/* You can add global styles to this file, and also import other style files */
:root {
  --danger-color: #d42a2a;
  --menu-header-bg: #252a34;
  --menu-bg: #1B1E24;
  --menu-link-bg: #131518;
  --menu-link-active-bg: #0f1011;
}

body, html {
  background: var(--surface-b);
  color: var(--text-color);
  font-size: 16px;
  font-family: var(--font-family);
  padding: 0;
  margin: 0;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

.text-center {
  text-align: center!important;
}

.text-right {
  text-align: right!important;
}

.text-thin {
  font-weight: 300;
}

.p-button-outlined.p-ripple .p-ink {
  background-color: rgba(23, 108, 255, 0.3);
}

.p-button-outlined.p-button-secondary.p-ripple .p-ink {
  background-color: rgba(0, 0, 0, 0.3);
}

.p-button-outlined.p-button-success.p-ripple .p-ink {
  background-color: rgba(104, 159, 56, 0.3);
}

.p-button-outlined.p-button-info.p-ripple .p-ink {
  background-color: rgba(56, 114, 159, 0.3);
}

.p-button-outlined.p-button-danger.p-ripple .p-ink {
  background-color: rgba(179, 53, 53, 0.3);
}

.auth .p-card-title, .auth .p-card-subtitle {
  text-align: center;
}

.adstock-lock-paragraph {
  font-size: 2em;
}

.container, .container-fluid {
  box-sizing: border-box;
  width: 100%;
  padding: 0 1em;
  margin: 0 auto;
}

.container-fluid {
  position: fixed;
  right: 0;
  left: 300px;
  bottom: 0;
  top: 0;
  overflow-y: auto;
  width: auto;
  padding-bottom: 3em;
}

.adstock-side-menu .p-ripple .p-ink {
  background: rgba(255, 255, 255, 0.1);
}

.adstock-side-menu {
  background: var(--menu-bg);
  width: 300px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

.adstock-side-menu .header {
  text-align: center;
  font-size: 2em;
  font-style: italic;
  padding: 0.4em 0;
  margin-bottom: 0.4em;
  color: white;
  background-color: var(--menu-header-bg);
}

.adstock-side-menu .adstock-menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.adstock-side-menu .adstock-menu-list li a {
  display: block;
  width: 100%;
  padding: 1em;
  transition: background-color 0.2s ease-in-out;
  color: white;
}

.adstock-side-menu .adstock-menu-list li a i {
  display: inline-block;
  width: 25px;
  text-align: center;
  margin-right: 5px;
}

.adstock-side-menu .adstock-menu-list li a:hover {
  background-color: var(--menu-link-bg);
}

.adstock-side-menu .adstock-menu-list li a.active {
  background-color: var(--menu-link-active-bg);
}

.adstock-side-menu .adstock-menu-list li a.active i {
  color: var(--primary-color);
}

.adstock-side-menu .adstock-menu-list li a.logout i {
  color: var(--danger-color);
}

table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.p-dialog-content p-dropdown {
  width: 100%;
}

.adstock-remotely-logged-in {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fffaad;
  padding: 0.5em 2em;
  text-align: center;
  z-index: 1000;
}

p-tag.employee .p-tag {
  background-color: #5a5a5a;
}

p-tag.admin .p-tag {
  background-color: #6f30c8;
}

p-tag.receiver .p-tag {
  background-color: #16858a;
}

.p-card-header {
  padding: 1em 1em 0;
}

.area-name {
  font-size: 1.4em;
}

@media all and (min-width: 784px) {
  .container {
    width: 768px;
  }
}

@media all and (min-width: 1008px) {
  .container {
    width: 992px;
  }
}

@media all and (min-width: 1216px) {
  .container {
    width: 1200px;
  }
}

@media all and (min-width: 1616px) {
  .container {
    width: 1600px;
  }
}

@media print {
  .adstock-remotely-logged-in {
    display: none;
  }
  #print-container .p-card {
    box-shadow: none;
  }
  #print-container .p-divider {
    display: none;
  }
}
